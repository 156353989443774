import React from 'react';
import Network from'../utils/Network';
import Util from '../utils/Utils'
import Toast from './toast';
import Solve from './solve';
import './Alarm.css';
import bg9 from './img/bg9.png';
import bg from './img/background.png';
import bg2 from './img/bg2 .png';
//地址上需要传给我的参数 bizType id assetId

class App extends React.Component{
    constructor(props){
        super(props);
        this.time=1500;
        this.message="提交成功";
        this.searchData=this.props.searchData;//获取到父组件传递过来的参数
        this.state={
            isShowBox:false,
            toastvisible:false,//处理弹窗=》不能少，通过点击事件实现的显隐
            alarmData:{},//报警详情数据
            solveData:{},//处理详情数据
        }

    }
    //挂载完成后 初始化数据
    componentDidMount(){
        //alert("id="+ this.searchData.id);
        //获取报警详情数据
        this.getAlarm();
    }

    //获取 报警详情 =》初始化数据
    getAlarm=()=>{
        let conf = {
            url: "alarm",
            method: "GET",
            params: {
                token:this.searchData.token,
                id:this.searchData.id,
                bizType:this.searchData.bizType
            },
        };
        Network.fetch(conf,(data)=>{
            //alert("接口请求成功");
            //alert(JSON.stringify(data));
            console.log("getAlarm() success");
            data=data?data:{};
            this.setState({alarmData:data});
            if(data.busiStatus==="FIXED")
            {
                this.getsolve();
            }
        },(error)=>{
            //alert("接口请求失败");
            //alert(JSON.stringify(error));
            console.log("getAlarm() error");
            console.log(error);
        })
    };
    //获取 处理详情 =》初始化数据
    getsolve=()=>{
        //向后台请求数据  获取处理时间等数据
        let conf={
            url:"history",
            method: "GET",
            params:{
                token:this.searchData.token,
                bizType:this.searchData.bizType,
                id:this.searchData.id,
                assetId:this.searchData.assetId
            }
        }
        Network.fetch(conf,(data)=>{
            console.log("getsolve() success");
            console.log(data);
            this.setState({solveData:data});
            this.setState({solveData:data[data.length-1]});
        },(error)=>{
            console.log("error");
            console.log(error);
        })
    }
    //点击事件 =》 提交处理
    solved=(mess,res)=>{
        console.log(mess);
        console.log(res);
        //不管用户是不是已处理 都要提交处理的措施和原因
        let conf={
            url:"solved",
            method:"PUT",
            params:{
                token:this.searchData.token,
                remark:JSON.stringify(mess),
                busiStatus:res,
                bizType:this.searchData.bizType,
                id:this.searchData.id
            }
        };
        console.log(conf.params);
        Network.fetch(conf,(data)=>{
            //重新加载页面，把刚刚提交后台的数据取出来更新。
            this.tosolve();
            this.getAlarm();
            this.setState({isShowBox:true});
            setTimeout(()=>{
                this.setState({isShowBox:false})
            },this.time);
        },(error)=>{
            this.tosolve();
            this.getAlarm();
            this.message="提交失败，请稍后重试";
            this.setState({isShowBox:true});
            setTimeout(()=>{
                this.setState({isShowBox:false})
            },this.time);
            console.log("error");
            console.log(error);
        })
    }
    //点击事件 =》 显隐弹窗
    tosolve=()=>{
        this.setState({toastvisible:!this.state.toastvisible});//切换弹窗
    }
    //小程序标志点击事件 =》跳转到小程序
    jumpTomp=()=>{
        window.wx.miniProgram.reLaunch({
            url: '/pages/service/service?from=h5&clickedTitleIndex=3'
        });
    }
    render() {
        return (
            <div style={{overflow:"hidden"}}>
                {/*提示框*/}
                {this.state.isShowBox?<div className="promptBox">{this.message}</div>:null}
                {/* 弹窗 */}
                {this.state.toastvisible?<Toast fun={this.tosolve} solved={this.solved} message={eval('('+this.state.alarmData.remark+")")}></Toast>:null}
                <div className="App" style={this.state.toastvisible?{filter:'blur(2px)',transform: 'scale(1.05)'}:{filter:'blur(0px)',transform: 'scale(1)'}}>
                    {/* 已解决的图标显示 */}
                    {this.state.alarmData.busiStatus=="FIXED"?<div style={{background:`url(${bg9}) no-repeat`}} className="solvetag"></div>:null}
                    {/* 右侧标签 */}
                    {this.state.toastvisible?null:<div className="rightbox" onClick={this.jumpTomp}><img src={bg2} alt="sass"></img></div>}
                    {/* 主界面头部 */}
                    <div className="topdiv">
                        <span className="topspan">报警详情</span>
                    </div>
                    {/* 主界面信息列表 */}
                    <div className="mslist" style={{backgroundSize:'cover', background:`url(${bg}) no-repeat`}}>
                        <ul>
                            <li><span>设备名称：</span><span>{this.state.alarmData.targetName}</span></li>

                            {
                                this.state.alarmData.assetType==="房间"?null:[
                                    <li key={1}><span>品牌：</span><span>{this.state.alarmData.assetBrand}</span></li>,
                                    <li key={2}><span>型号：</span><span>{this.state.alarmData.assetModel}</span></li>,
                                    <li key={3}><span>资产编号：</span><span>{this.state.alarmData.assetNumber}</span></li>,
                                    <li key={4}><span>设备SN号：</span><span>{this.state.alarmData.serialNumber}</span></li>
                                ]
                            }
                            <li><span>对象类型：</span><span>{this.state.alarmData.assetType}</span></li>
                            <li><span>报警类型：</span><span>{this.state.alarmData.eventType}</span></li>
                            <li><span>报警参数：</span><span>{this.state.alarmData.measureCode}</span></li>
                            <li><span>报警原因：</span><span>{this.state.alarmData.eventResult}</span></li>
                            <li><span>报警时间：</span><span>{Util.dateFtttttt(this.state.alarmData.startTime)}</span></li>
                            <li><span>持续时间：</span><span>{Util.dateF(this.state.alarmData.duration)}</span></li>
                        </ul>
                    </div>
                    {/* 处理按钮 */}
                    {this.state.alarmData.busiStatus=="OPEN"?<div className="foot"><input type="button" value="立即处理"  onClick={this.tosolve} style={this.state.toastvisible?{pointerEvents:'none'}:{}}/></div>:null}
                    {/* 已处理页面 */}
                    {this.state.alarmData.busiStatus=="FIXED"?<Solve message={eval('('+this.state.alarmData.remark+")")} solvedata={this.state.solveData}></Solve>:null}
                    {/*末尾加个换行*/}
                    {this.state.alarmData.busiStatus=="FIXED"?<br/>:null}
                </div>
            </div>
        )
    }
}
export default App;
