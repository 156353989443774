import React from 'react';
import Uitl from '../utils/Utils'

class Solve extends React.Component{
    constructor(props){
        super(props);
    };
    componentDidMount(){
        this.setState({

        });
    };
    render() {
        return (
            <div className="solve">
                <ul>
                    <li className="solveTop">
                        <div style={{overflow:"hidden", background:`url(${this.props.solvedata.photo}) black no-repeat`}}></div>
                        <span>{this.props.solvedata.userName}</span>
                        <span>{Uitl.dateFtttttt(this.props.solvedata.timeStamp)}</span>
                    </li>
                    <li>
                        <span>诊断原因：</span><span>{this.props.message.diagnoseReason}</span>
                    </li>
                    <li><span>处理措施：</span><span>{this.props.message.correctiveAction}</span></li>
                    <li className="lastli">
                        <div>
                        <span className="footspan"></span>
                        <span>处理共耗时：</span><span>{Uitl.dateF(this.props.solvedata.duration)}</span>
                        </div>
                    </li>
                </ul>
            </div>
        ); 
    };
}

export default Solve;