
let domains=[
    "/api/",//跨域地址
    "https://saas.ilabservice.cloud/", //正式环境地址
    "https://saastest.ilabservice.cloud/",//测试环境
    "/"//反向代理转发后
]


let appConfig = {
     DOMAIN: domains[3],//正式环境地址
};
const urlMap = {
    "bizType": "platform/web/api/v3/unsecure/bizType",   //GET phone
    "appointment": "platform/wechat/api/v3/secure/lease/record",   //GET 预约详情列表
    "commitCancle":"platform/wechat/api/v3/secure/assets/lease/{id}",   //提交取消预约的备注  PUT
    "repair": "platform/wechat/api/v3/secure/monitor/target/{deviceId}",   //GET 维修
    "repairPlus": "wechat/api/v3/secure/maintenance/customer/maintenance/{recordId}",//维修详情
    "maintenance":"wechat/api/v3/secure/maintenance/customer/maintenance/plan_record",//保养详情 recordId=1113&bizType=66
    "alarm": "platform/wechat/api/v3/secure/event/record/{id}",   //GET 报警详情 id
    "solved": "platform/wechat/api/v3/secure/event/{id}", //put 提交报警处理
    "history": "platform/wechat/api/v3/secure/asset/{assetId}/event/{id}/history"    //get 获取报警处理记录
};

const UrlHelper = {
    replaceMiddle: function (path, params) {
        try {
            if (!path) {
                return {path: path, params: params};
            }
            while (path.indexOf('{') != -1) {
                let start = path.indexOf('{');
                let end = path.indexOf('}');
                let middle = path.substring(start, end + 1);
                Object.keys(params).forEach(function (key) {
                    key = key.trim();
                    let sd = '{' + key + '}';
                    if (middle === sd) {
                        let value = params[key];
                        path = path.replace(middle, value);
                        delete params[key];
                        return false;
                    }
                });
            }
        } catch (e) {
            let sa=100;
        }
        return {path: path, params: params};
    },

    getUrl: function (urlkey, params) {
        let path = urlMap[urlkey];
        if (!path) {
            return '';
        }

        //1,替换掉中间的值
        if (path.indexOf('{') != -1) {
            const result = UrlHelper.replaceMiddle(path, params);
            path = result.path;
            params = result.params;
        }
        //2，拼接
        let paramStr="";
        if(params){
            paramStr= Object.keys(params)
                .map(function (key) {
                    key = key.trim();
                    return params[key] === undefined ? '' : key + '=' + params[key];
                })
                .filter(function (id) {
                    return id;
                })
                .join('&');
        }

        if (paramStr) {
            return appConfig.DOMAIN + path + '?' + paramStr;
        }
        return appConfig.DOMAIN + path;
    },

    getDomainUrl: function (domain,urlkey, params) {
        let path = urlMap[urlkey];
        if (!path) {
            return '';
        }

        //1,替换掉中间的值
        if (path.indexOf('{') != -1) {
            const result = UrlHelper.replaceMiddle(path, params);
            path = result.path;
            params = result.params;
        }
        //2，拼接
        let paramStr="";
        if(params){
            paramStr= Object.keys(params)
                .map(function (key) {
                    key = key.trim();
                    return params[key] === undefined ? '' : key + '=' + params[key];
                })
                .filter(function (id) {
                    return id;
                })
                .join('&');
        }

        if (paramStr) {
            return (domain||appConfig.DOMAIN) + path + '?' + paramStr;
        }
        return (domain||appConfig.DOMAIN) + path;
    },

    postUrl: function (urlkey, params, isForm) {
        let path = urlMap[urlkey];
        if (!path) {
            return {};
        }
        //1,替换掉中间的值
        if (path.indexOf('{') !== -1) {
            const result = UrlHelper.replaceMiddle(path, params);
            path = result.path;
            params = result.params;
        }
        //2,加上域名
        path = appConfig.DOMAIN + path;

        //3,判断是否是form
        if (isForm) {
            let formData = new FormData();
            Object.keys(params).map((key) => {
                if(key !=="image"){
                    formData.append(key, params[key]);
                }else {
                    let obj=params["image"];
                    let file = {uri:obj.path, type: 'multipart/form-data', name: obj.filename};
                    formData.append('image', file);
                }
            });
            return {url: path, body: formData};
        } else {
            return {url: path, body: JSON.stringify(params)};
        }
    },

    postDomainUrl: function (domain,urlkey, params, isForm) {
        let path = urlMap[urlkey];
        if (!path) {
            return {};
        }
        //1,替换掉中间的值
        if (path.indexOf('{') !== -1) {
            const result = UrlHelper.replaceMiddle(path, params);
            path = result.path;
            params = result.params;
        }
        //2,加上域名
        path = (domain||appConfig.DOMAIN) + path;

        //3,判断是否是form
        if (isForm) {
            let formData = new FormData();
            Object.keys(params).map((key) => {
                if(key !=="image"){
                    formData.append(key, params[key]);
                }else {
                    let obj=params["image"];
                    let file = {uri:obj.path, type: 'multipart/form-data', name: obj.filename};
                    formData.append('image', file);
                }
            });
            return {url: path, body: formData};
        } else {
            return {url: path, body: JSON.stringify(params)};
        }
    },

};
export default UrlHelper


