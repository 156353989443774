import React from 'react';

import UrlHelper from "./UrlHelper";

const DEFAULT_REQUEST_CONF = {
    url: '',
    params: null,
    method: 'GET',
    isForm: false,
    domain: null,  //域名与appConfig.DOMAIN 中不同时使用，注意打包时改成正式域名
};

let token;
let bizType;
const Network = {
    setToken: (to) => {
        token = to;
    },
    getToken: () => {
        return token;
    },
    setBizType: (str) => {
        bizType = str
    },

    fetch: function (conf, onSuccess, onFailure) {
        if (!conf) {
            conf = {};
        }
        conf = {...DEFAULT_REQUEST_CONF, ...conf};

        if (bizType != null && bizType != "") {
            if (conf.params) {
                conf.params.bizType = bizType;
            } else {
                conf.params = {
                    bizType: bizType
                }
            }
        }

        let method = (conf.method || 'GET').toUpperCase();
        let options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'X-Authorization': 'Bearer ' + conf.params.token,
                'x-language': 'zh',
                // "Access-Control-Allow-Origin": "*",
                // "Access-Control-Allow-Credentials":"true",
                // "Access-Control-Allow-Methods":"*",
                // "Access-Control-Allow-Headers":"Content-Type,Access-Token",
                // "Access-Control-Expose-Headers":"*"
            },
        };
        //获取url
        let url;
        if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
            if (conf.isForm) {
                options.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
            }
            const result = conf.domain ? UrlHelper.postDomainUrl(conf.domain, conf.url, conf.params, conf.isForm) : UrlHelper.postUrl(conf.url, conf.params, conf.isForm);
            url = result.url;
            options.body = result.body;
        } else if (method === 'GET') {
            url = conf.domain ? UrlHelper.getDomainUrl(conf.domain, conf.url, conf.params) : UrlHelper.getUrl(conf.url, conf.params);
        }

        fetch(url, options)
            .then(response => response.json())
            .then(json => {
                if (json && Number(json.code) === 0) {
                    console.log('请求网络成功：');
                    console.log(url);
                    console.log(options);
                    console.log(json);
                    if (onSuccess) {
                        onSuccess(json.data)
                    }
                }
                else {
                    if (json.status == 500) {
                        json.message = '网络请求错误';
                    }
                    onFailure(json);
                }

            })
            .catch(error => {
                console.log('请求网络失败：');
                console.log(url);
                console.log(options);
                console.log(error);
                error.message = error.message ? error.message : "请求网络失败";
                if ("Network request failed" == error.message) {
                    error.message = "请求网络失败";
                }
                if (onFailure) {
                    onFailure(error);
                }
            });
    }
};
export default Network
