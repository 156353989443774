

const UrlHelper = {
    /**
     * 时间格式化处理
     * yyyy.MM.dd hh:mm:ss
     */
    dateFtttttt: function (long) {
        if(!long){
            return ""
        }
        var date = new Date(long);
        var YY = date.getFullYear() + '.';
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
        var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return YY + MM + DD + " " + hh + mm + ss;
    },


    /**
     * 时间格式化处理
     * 将时间戳的差转化为  "10时20分10秒"
     */
    dateF: function (longDiff) {
        let continueTime;
        if (longDiff <= 1000) {
            continueTime = '0分0秒';
        } else {
            //秒
            let ss = Math.floor((longDiff / 1000) % 60);
            //分钟
            let mm = Math.floor(longDiff / 1000 / 60);
            //小时
            let hh = '';
            if (mm >= 60) {
                hh = Math.floor(mm / 60);
                mm = mm % 60;
                if(hh<24)
                continueTime = hh + '时' + mm + '分' + ss + '秒';
                else {
                    let dd=Math.floor(hh/24);
                    hh=hh%24;
                    continueTime=dd +'天'+hh + '时' + mm + '分' + ss + '秒';
                }
            } else {
                continueTime = mm + '分' + ss + '秒';
            }
        }
        return continueTime
    },

    /*
    * 没有秒的时间
    *
    * */
    TimeFormat: function (timenum){
        var time= new Date(timenum);
        var year=time.getFullYear();
        var month=time.getMonth()+1>=10?time.getMonth()+1:'0'+(time.getMonth()+1);
        var date=time.getDate()>=10?time.getDate():"0"+time.getDate();
        var hour=time.getHours()>=10?time.getHours():'0'+time.getHours();
        var minute=time.getMinutes()>=10?time.getMinutes():'0'+time.getMinutes();
        return `${year}.${month}.${date}-${hour}:${minute}`;
    },

    /*
    *将地址的search 转换成一个对象
    *
    *
    */
    searchToObj:function (url){
        var obj = {};
        if (url.indexOf('?') !== -1) {
            var url = url.substring(url.indexOf('?') + 1);
        } else {
            return;
        }
        var arr = url.split('&');
        arr.forEach(function (val) {
            var brr = val.split('=');
            obj[brr[0]] = brr[1];
        });
        return obj;
    }

};
export default UrlHelper


