import React from 'react';
import Util from '../utils/Utils';
import Network from "../utils/Network";
import './Repair.css';
import Step from './repairStep';
import bg2 from './img/bg2 .png';
import bg1 from './img/bg2.PNG';
import noimg from './img/none.png'

//地址需要传的参数 deviceId recordId bizType
//status 有几种状态？ open ack schedule cancle finish 那么怎么判断是否已上门

class RightTag extends React.Component{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="repairRight" onClick={this.props.jumpfun}>
            <img src={bg2} alt="saas"/>
           </div>
        );
    }
}

class Repair extends React.Component{
    constructor(props){
        super(props);
        this.searchData=this.props.searchData;//获取参数
        this.state={
            repairData: {},//维修详细数据
            files:{}//处理过后的图片集合
        }
    };
    componentDidMount() {
        //获取维修详情数据
        this.getRepairDetil();
    }
    //获取维修详情
    getRepairDetil=()=>{
        let conf={
            url:"repairPlus",
            method: "GET",
            params: {
                bizType:this.searchData.bizType,
                recordId:this.searchData.recordId,
                token:this.searchData.token
            }
        };
        Network.fetch(conf,(data)=>{
            console.log(data);
            this.setState({repairData:data});
            this.separationImg();
        },(error)=>{
            console.log("getRepairDetil()=>error");
        })

    }
    //分离图片=》故障图片列表 服务报告图片
    separationImg=()=>{
        let fault=[];
        let service=[];
        this.state.repairData.files.map((val,index,arr)=>{
            if(val.type==='FAULT')
            {
                fault.push(<div><img src={val.url} alt="故障图片"/></div>)
            }
            else {
                service.push(<div><img src={val.url} alt="服务报告"/></div>)
            }
        });
        if(fault.length===0){
            fault.push(<div><img src={noimg} alt="故障图片"/></div>)
        }
        if(service.length===0){
            service.push(<div><img src={noimg} alt="服务报告"/></div>)
        }
        this.setState({files:{fault:fault,service:service}});
    }
    //跳转小程序页面
    jumpTomp=()=>{
        console.log(window);
        window.wx.miniProgram.navigateTo({
            url: `/pages/record/repair/repair_list/repair_list?assetId=${this.searchData.deviceId}`
        });
    };
    //
    render(){
        return(
            <div className="repair">
                {/* 右侧标签 */}
                <RightTag  jumpfun={this.jumpTomp}></RightTag>
                {/* 版心居中 */}
                <div className="repaircenter">
                    <div className="repairTop">我的维修</div>
                    {/* 故障信息 */}
                    <div className="repairmess">
                        {/* 顶部圆形图片 */}
                        <div className="messtop">
                            <div style={{background: `url(${this.state.repairData.assetPhoto?this.state.repairData.assetPhoto:noimg}) no-repeat`}}></div>
                        </div>
                        {/* 右侧位置信息*/}
                        <div className="pointimg">
                            <span></span><span>{this.state.repairData.locationDesc?this.state.repairData.locationDesc:"未知"}</span>
                        </div>
                        <ul className="repairlist">
                            <li> <span>设备名称</span> <span>服务单号</span> </li>
                            <li> <span>{this.state.repairData.deviceName?this.state.repairData.deviceName:"暂无"}</span> <span>{this.state.repairData.code?this.state.repairData.code:"暂无"}</span> </li>
                            <li> <span>故障类型</span> <span>故障描述</span> </li>
                            <li> <span>{this.state.repairData.faultType?this.state.repairData.faultType:"暂无"}</span> <span>{this.state.repairData.description?this.state.repairData.description:"暂无"}</span> </li>
                            <li>故障图片</li>
                            <li className="errorimg">
                                {this.state.files.fault}
                            </li>
                            <li>备注</li>
                            <li>{this.state.repairData.remark?this.state.repairData.remark:"暂无"}</li>
                            <li>服务报告</li>
                            <li className="errorimg">
                                {this.state.files.service}
                            </li>
                        </ul>
                    </div>
                    {/* 维修进度 */}
                    <span className="ratespan">维修进度</span>
                    <div className="repairRate">
                        {this.state.repairData.timerShaftRecord?this.state.repairData.timerShaftRecord.reverse().map((val,index,array)=>{return <Step stepData={val} key={index} sindex={index} arrLength={array.length}></Step>;}):null}
                    </div>
                </div>
            </div>
        );
    }

}
export default Repair;