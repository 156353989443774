import React from 'react';
import Network from'../utils/Network';
import Util from '../utils/Utils'
import './maintenance.css'

//需要给我的参数 recordId bizType

let line=<div className='linediv'><div></div><div></div><div></div></div>;
class  Maintenance extends React.Component{
    constructor(props){
        super(props);
        this.state={
            mainData:{}
        };
    };
    //挂载完成初始化数据
    componentDidMount() {
        this.getmaintence();
    }

    //获取保养详情
    getmaintence=()=>{
        let conf={
            url:"maintenance",
            method: "GET",
            params: {
                bizType: "66",
                recordId:"1113",
                token:"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxODYxNjY1MTIwMiIsInNjb3BlcyI6W10sInVzZXJfaWQiOjEyMSwiaWF0IjoxNjA2MDUyOTg2LCJleHAiOjE2MDYzMTIxODZ9.ngsOSnNs3Xmh1BQAfQdJ97eaSgceDgs1FUrofyvmhNdHiv2ortoazaWW6jp-9TxiXldZ7tD5ADWgyANdfj6h7Q"
            }
        };
        Network.fetch(conf,(data)=>{
            console.log(data);
            this.setState({mainData:data[0]?data[0]:{}});
        },(error)=>{
            console.log("-------getmaintence()=>error---");
            console.log(error);
        })

    }

    render(){
        return (
            <div className="maintenance">
                {/* 版心居中 */}
                <div className="maintenCenter">
                    {/* top */}
                    <div className="miantop">服务详情</div>
                    {/* mid */}
                    <div className="mainmid">
                        <div><span className="firstfont">{this.state.mainData.maintenanceStatus==='FINISH'?"已":"待"}</span>{this.state.mainData.maintenanceStatus==='FINISH'?"完成":"服务"}</div>  <div>{this.state.mainData.faultType}</div>
                    </div>
                    {/* foot */}
                    <div className="mainfoot">
                        <div className="mainfootlist">
                            <ul>
                                <li><span>设备名称：</span>  <span>  {this.state.mainData.deviceName}   </span></li>
                                <li><span>资产编号：</span>  <span>  {this.state.mainData.asset}  </span></li>
                                <li><span>设备SN号：</span>  <span>  {this.state.mainData.serialNo}  </span></li>
                                <li><span>服务商：</span>    <span>  {""}  </span></li>
                                <li><span>品牌：</span>    <span>  {""}  </span></li>
                                <li><span>型号：</span>      <span>  {this.state.mainData.model}  </span></li>
                            </ul>
                        </div>
                        {/*分割线*/}
                        {this.state.mainData.maintenanceStatus==='FINISH'?<div className="divider"></div>:line}
                        <div className="paln">
                            <div>计划服务日期</div>
                            <div>{Util.dateFtttttt(this.state.mainData.planTime)}</div>
                            {this.state.mainData.maintenanceStatus==='FINISH'?
                                <div className="finishtime">
                                    <div></div>
                                    <div>实际完成日期</div>
                                    <div>{Util.dateFtttttt(this.state.mainData.endTime)}</div>
                                </div>
                                :null
                            }
                        </div>
                        {/*分割线*/}
                        {this.state.mainData.maintenanceStatus==='FINISH'?line:null}
                        {this.state.mainData.maintenanceStatus==='FINISH'?
                            <div className="serviceRe">
                                <div><img src={this.state.mainData.imageUrl?this.state.mainData.imageUrl:this.state.mainData.files[0].url} alt="serviceReport"/></div>
                                服务报告
                            </div>
                            :null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default Maintenance;