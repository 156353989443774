import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Alarm from './alarm/Alarm';
import Appointment from './appointment/Appointment';
import Repair from './repair/Repair';
import Maintenance from "./maintenance/maintenance";
import Util from './utils/Utils'
import App from "./alarm/Alarm";
import Network from "./utils/Network";
import Toast from "./alarm/toast";
import bg9 from "./alarm/img/bg9.png";
import bg2 from "./alarm/img/bg2 .png";
import bg from "./alarm/img/background.png";
import Solve from "./alarm/solve";


class Router extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    //挂载完成后 初始化数据
    componentDidMount() {

    }

    render() {

        //alert("location==" + JSON.stringify())
        //let options=window.location?window.location.search.search:null
        let options=Util.searchToObj(window.location.search);
        //alert(JSON.stringify(options));

        //报警界面
        if(options.type==3){
            return (
                <div>
                    <Alarm searchData={options}/>
                </div>
            );
        }
        //报修界面
        if(options.type==2){
            return (
                <div>
                    <Repair searchData={options}/>
                </div>
            );
        }
        //预约界面
        if(options.type==1){
            return (
                <div>
                    <Appointment searchData={options}/>
                </div>
            );
        }
        //保养界面
        if(options.type==4){
            return (
                <div>
                   <Maintenance searchData={options}/>
                </div>
            );
        }

    }
}

ReactDOM.render(
    <React.StrictMode>
        <Router/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
