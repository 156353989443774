import React from 'react';
import Network from "../utils/Network";
import './Appointment.css';
import Nostart from'./nostart'
import bg2 from './img/bg2 .png'
//地址上需要传的参数  bizType recordId好像不需要了
class RightTag extends React.Component{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="appointRight" onClick={this.props.jumpfun}>
            <img src={bg2} alt="saas"/>
           </div>
        );
    }
}

class RemaskToast extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            remarkMess: ""
        }
    };
    whenchange=(e)=>{
        this.setState({remarkMess:e.target.value});
    };

    render(){
        return(
            <div className="remarkToast">
                {/* 头部图片 */}
                <div className="remaskTop"></div>
                {/* 右上角取消按钮 */}
                <div className="remaskCancle" onClick={this.props.funhidden}></div>
                <div>备注：</div>
                <textarea placeholder="选填" value={this.state.remarkMess} onChange={(e)=>{this.whenchange(e)}}>

                </textarea>
                <input type="button" value="取消" onClick={this.props.funhidden}></input>
                <input type="button" value="确定" onClick={()=>{this.props.funCommit(this.state.remarkMess)}}></input>
            </div>
        );
    }
}

class Appointment extends React.Component{
    constructor(props){
        super(props);
        this.searchData=this.props.searchData;//获取到父组件传递过来的参数
        this.page=0;//记录已经加载的最大页数
        this.state={
            toastVisible:false,
            orderData:null,
            OrderList:[],
            id:null,
            toast:""
        }
    }
    //挂载完成后请求数据进行初始化
    componentDidMount() {
        this.getOrderList();
    }
    //获取预约列表
    getOrderList=()=>{
        //获取数据时 不能再触发事件
        document.getElementsByTagName("body")[0].onscroll=()=>{};
        let conf = {
            url: "appointment",
            method: "GET",
            params: {
                offset:this.page,   //页码 从 0 开始
                bizType:this.searchData.bizType,
                limit:11,   //一页的数据量限制
                token:this.searchData.token
            },
        };
        Network.fetch(conf,(data)=>{
            console.log("获取预约列表成功");
            console.log(data);
            this.setState({orderData:data});
            //初始化UI
            this.initUI();
            //页数 +1
            this.page=this.page+1;
            //如果获取到一个空数组，说明已经拿完数据了
            if(data.length===0)
            {
                document.getElementsByTagName("body")[0].onscroll=()=>{};
                this.setState({toast:"--暂无更多--"})
            }
            else {
                this.whenOnFoot();//请求结束后 添加一个滚动事件 滚动到底部后继续请求数据，
            }
        },(error)=>{
            console.log("~~获取预约列表失败~~")
            console.log(error)
        })
    }
    //确定按钮点击事件 提交备注、重新加载页面
    comitRemark=(remarkMess)=>{
        console.log(remarkMess);
        //获取数据 异步提交备注
        let conf = {
            url: "commitCancle",
            method: "PUT",
            params: {
                bizType:this.searchData.bizType,
                id:this.state.id,
                status:3,
                remark:remarkMess,
                token:this.searchData.token
            },
        };
        Network.fetch(conf,(data)=>{
            //重新加载页面，把刚刚提交后台的数据取出来更新。
            window.location.reload();
        },(error)=>{
            console.log("提交失败error");
            console.log(error);
        })
    }
    //滚动条到达底部时 =》继续请求数据
    whenOnFoot=()=>{
        let body=document.getElementsByTagName("body")[0];
        body.onscroll=()=>{
           // let allHeight=body.scrollHeight;
           // let scrollTop=document.getElementsByTagName("html")[0].scrollTop;
           // let cilenHeight=document.getElementsByTagName("html")[0].clientHeight;
           // allHeight-scrollTop-cilenHeight===0
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if(scrollHeight > clientHeight && scrollTop + clientHeight + 5 >= scrollHeight)//距底部 5 px 开始新的加载
            {
              this.getOrderList();
            }
        }
    }
    //取消预约点击事件 显示弹窗
    cacleToast=(id)=>{
        this.setState({toastVisible:true});
        this.setState({id:id});
    }
    //取消按钮点击事件  隐藏弹窗
    hiddenToast=()=>{
        this.setState({toastVisible:false});
    }
    //跳转小程序页面
    jumpTomp=()=>{
        console.log(window);
        window.wx.miniProgram.navigateTo({
            url: `/pages/mine/lease/index?from=h5`
        });
    };
    //初始化界面=>列表渲染
    initUI=()=>{
        console.log(this.state.orderData);
        let arr=this.state.orderData.map((val,index,arr)=>{
            //未开始
            if(val.status===0)
                return <Nostart step={1} funtoast={this.cacleToast} data={val} key={val.id.toString()}> </Nostart>;
            //进行中
            if(val.status===1)
                return <Nostart step={2} data={val} key={val.id.toString()}> </Nostart>;
            //已完成
            if(val.status===2)
                return <Nostart step={3} data={val} key={val.id.toString()}> </Nostart>;
            //已取消
            if(val.status===3)
                return <Nostart step={0}  data={val} key={val.id.toString()}> </Nostart>;
        });
        //把每次查询获取到的列表都放在 OrderList
        this.setState({OrderList:this.state.OrderList.concat(arr)});
    }
    render(){
        return (
            <div>
                {/* 备注填写弹窗 */}
                {this.state.toastVisible?<RemaskToast funhidden={this.hiddenToast} funCommit={this.comitRemark}></RemaskToast>:null}
                <div className="appointment">
                {/* 右侧标签 */}
                {this.state.toastVisible?null:<RightTag jumpfun={this.jumpTomp}></RightTag>}
                {/* 版心居中 */}
                    <div className="appoint" style={{filter:`blur(${this.state.toastVisible?2:0}px)`}} >
                        <div className="appointTop">我的预约</div>
                        {this.state.OrderList}
                        <div className={"foottoast"}>{this.state.toast}</div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Appointment;