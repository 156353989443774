import React from 'react';
import Util from '../utils/Utils'
import imgFinsh from './img/finish.png';
import imgPoint from './img/tag.PNG';
import imgservice from './img/service.png';
import imgdd from './img/dd.png';
import imgplease from './img/plese (1).png';
import cancle from './img/icon_cancel.png'
class repairStep extends React.Component{
    constructor(props){
        super(props);
        this.isReturn=1;
        this.state={
            sty:{bg:imgFinsh,setfont:{fontSize: "4vw",color:"#50c666"}},
            mess:{topmess:"",midmess:"",footmess:"",lastmess:""}
        }
    }
    componentWillMount(){//渲染组件前 通过判断 设置不同的样式
        this.initSty();
    }
    //根据数据设置组件的样式
    initSty=()=>{
        let step=this.props.stepData.status;
        console.log(step);
        let data=this.props.stepData;
        let index=this.props.sindex;
        let arrleng=this.props.arrLength-1;
        //订单完成 
        if(step==="FINISH")
        {
            this.setState({sty:{bg:imgFinsh,setfont:{fontSize: "4vw",color: "#50c666"}}});
            this.setState({mess:{topmess:"完成服务",midmess:"上门时间",footmess:"状态",lastmess:"已上门"}})
        }
        //订单受理
        else if(step==='ACK'&&index===arrleng-1)
        {
            this.setState({sty:{bg:imgdd,setfont:{fontSize: "4vw",color: "#f74972"}}});
            this.setState({mess:{topmess:"订单受理",midmess:"受理时间",footmess:"服务商公司",lastmess:data.company?data.company:"未知"}})
        }
        //维修申请订单提交
        else if(step==="OPEN")
        {   console.log("open"+index);
            this.setState({sty:{bg:imgplease,setfont:{fontSize: "4vw",color: "#4a90e2"}}});
            this.setState({mess:{topmess:"报修申请",midmess:"报修时间",footmess:"报修人",lastmess:data.name?data.name:"未知"}})
        }
        //订单取消
        else if(step=="CANCEL")
        {
            this.setState({sty:{bg:cancle,setfont:{fontSize: "4vw",color:"#6363DB"}}});
            this.setState({mess:{topmess:"订单受理",midmess:"取消时间",footmess:"取消人",lastmess:data.name?data.name:"未知"}})
        }
        //上门服务=>待上门
        else if(step==="SCHEDULE"&&index===0)
        {
            //多次上门服务
            if(index<arrleng-3)
            {
                this.setState({sty:{bg:imgPoint},setfont:{fontSize: "3vw"}});
                this.setState({mess:{lastmess:"待上门"}});
            }
            else {
                this.setState({sty:{bg:imgservice,setfont: {fontSize: "4vw",color: "#f6a93b"}}});
                this.setState({mess:{topmess:"上门服务",midmess:"上门时间",footmess:"状态",lastmess:"待上门"}});
            }
        }
        //上门服务=》已上门
        else if(step==='ACK'&&index<arrleng-1){
            //多次上门服务
            if(index<arrleng-4)
            {
                this.setState({sty:{bg:imgPoint},setfont:{fontSize: "3vw"}});
                this.setState({mess:{lastmess:"已上门"}});
            }
            else {
                this.setState({sty:{bg:imgservice,setfont: {fontSize: "4vw",color: "#f6a93b"}}});
                this.setState({mess:{topmess:"上门服务",midmess:"上门时间",footmess:"状态",lastmess:"已上门"}});
            }
        }
        else {
            this.isReturn=0;
        }
    }

    render(){
        if(this.isReturn===0){
            return null;
        }
        else if((this.props.stepData.status==='SCHEDULE'||this.props.stepData.status==='ACK')&&this.props.sindex<this.props.arrLength-4)
        {
            return(
                <div className="repairstep">
                    {/* 右侧描述 */}
                    <div className="stepright">
                        <ul>
                            <li> 
                                {/* 左侧图标所在div */}
                                <div className="stepleft" style={{background:`url(${this.state.sty.bg})`}} ></div>
                                <span>上门时间：</span> <span>{Util.TimeFormat(this.props.stepData.time)}</span>
                            </li>
                            <li> <span>状态：</span> <span>{this.state.mess.lastmess}</span> </li>
                        </ul>
                    </div>
                </div>
            );
        }
        else
        {
            return (
            <div className="repairstep">
                {/* 右侧描述 */}
                <div className="stepright">
                    <ul>
                        <li>
                            {/* 左侧图标所在div */}
                            <div className="stepleft" style={{background:`url(${this.state.sty.bg})`}} ></div>
                            <span style={this.state.sty.setfont}>{this.state.mess.topmess}</span>
                        </li>
                        <li> <span>{this.state.mess.midmess}：</span> <span>{Util.TimeFormat(this.props.stepData.time)}</span> </li>
                        <li> <span>{this.state.mess.footmess}：</span> <span>{this.state.mess.lastmess}</span> </li>
                    </ul>
                </div>
            </div>
        );
        }
    }
};
export default repairStep;