import React from "react";
import Utli from "../utils/Utils"
import nostart from './img/bg1.png';
import starting from './img/bg3.png';
import cacle from './img/bg4.png';
import finish from './img/bg5.png';

class Listfoot extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
            mess:this.props.operation
        }
    }
    //取消预约 / 查看备注 点击事件
    btnclick=()=>{
        this.props.funtoast();
        if(this.state.mess==="返回")
            this.setState({mess:"查看备注"});
        if(this.state.mess==="查看备注")
            this.setState({mess:"返回"});
    }

    render(){
        return(
            <div className="listFoot" onClick={this.btnclick}>
                {this.state.mess}
            </div>
        );
    }
}

class Nostart extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tagbg:nostart,//各个阶段 顶部背景标志不同
            lfvisible:null,//底部是否有操作这一项
            status:"",//各个阶段顶部的描述文字
            listVisible:true,//是否将列表  替换成 备注
            operation:"",//未开始 =》取消预约操作，已取消=》查看备注操作
            stepcolor:"",//各个阶段顶部文字的颜色
            listcolor:""//
        }
    }
    componentDidMount() {
        this.initUI();
    }


    //初始化UI
    initUI=()=>{
        var step=this.props.step;
        switch (step) {
            case 1: {
                this.setState({tagbg:nostart});
                this.setState({stepcolor:"#dfa53c"});
                this.setState({status:"未开始"});
                this.setState({lfvisible:<Listfoot operation={"取消预约"} funtoast={()=>{this.props.funtoast(this.props.data.id)}}></Listfoot>});
                break;
            }
            case 2: {
                this.setState({tagbg:starting});
                this.setState({stepcolor:"#4496fc"});
                this.setState({status:"进行中"});
                break;
            }
            case 3:{
                this.setState({tagbg: finish});
                this.setState({listcolor:"#87878a"});
                this.setState({stepcolor:"#268b54"});
                this.setState({status:"已完成"});
                break;
            }
            default: {
                this.setState({tagbg:cacle});
                this.setState({listcolor:"#87878a"});
                this.setState({stepcolor:"#af4646"});
                this.setState({status:"已取消"});
                this.setState({lfvisible:<Listfoot operation={"查看备注"} funtoast={this.checkRemark} data={this.props.data.remark}></Listfoot>});
                break;
            }
        }
    }
    //查看/返回 备注点击事件  查看取消预约的备注
    checkRemark=()=>{
        this.setState({listVisible:!this.state.listVisible});
    }
    render(){
        return (
            <div className="appointBody" style={{"color":this.state.listcolor}}>
                <ul className="messlist">
                    {   this.state.listVisible?
                        [
                            <li key={"1"}> <span>开始时间</span> <span className="spantop" style={{"color":this.state.stepcolor}}>{this.state.status}</span> <span>结束时间</span> </li>,
                            <li key={"2"} style={{background:`url(${this.state.tagbg}) no-repeat`}}> <span>{Utli.TimeFormat(this.props.data.startTime)}</span> <span></span> <span>{Utli.TimeFormat(this.props.data.endTime)}</span> </li>,
                            <li key={"3"}> <span>设备名称</span> <span>资产编号</span> </li>,
                            <li key={"4"}> <span>{this.props.data.assetName}</span> <span>{this.props.data.assetNumber}</span> </li>,
                            <li key={"5"}> <span>设备SN号</span> <span>创建时间</span> </li>,
                            <li key={"6"}> <span>{this.props.data.serialNumber}</span> <span>{Utli.TimeFormat(this.props.data.createTime)}</span> </li>
                        ]
                        :
                        [
                            <li key={"11"} className={"remasktop"}>备注</li>,
                            <li key={"22"} className="remaskLi">{this.props.data.remark}</li>
                        ]
                    }
                </ul>
                {this.state.lfvisible}
            </div>
        );
    }
}
export default Nostart;