import React from 'react';
import bg1 from './img/bg5.png';
import bg2 from './img/bg6.png';
class Toast extends React.Component{
    constructor(props){
        super(props);
        this.state={
            reason:props.message?props.message.diagnoseReason:"",
            measure:props.message?props.message.correctiveAction:"",
            setbg1:{background:`url(${bg1})`},
            setbg2:{background:`url(${bg2})`},
            setfont1:{color:"#3e6bec"},
            setfont2:{color:"#bcbcbc"},
            res:"FIXED"
        }
    }
    whenchange=(inputindex,e)=>{
        if(inputindex===0)
        {
            this.setState({reason:e.target.value});
        }
        else
        this.setState({measure:e.target.value});
    };
    nosolve=()=>{
        this.setState({res:"FIXED"})
        if(this.state.bg1==={background:`url(${bg1})`})
        {}
        else
        {
            this.setState({bg1:{background:`url(${bg1})`}});
            this.setState({bg2:{background:`url(${bg2})`}});
            this.setState({setfont2:{color:"#bcbcbc"}});
            this.setState({setfont1:{color:"#3e6bec"}});
        }
    };
    solved=()=>{
        this.setState({res:"OPEN"});
        if(this.state.bg2==={background:`url(${bg1})`})
        {}
        else
        {
            this.setState({bg2:{background:`url(${bg1})`}});
            this.setState({bg1:{background:`url(${bg2})`}});
            this.setState({setfont2:{color:"#3e6bec"}});
            this.setState({setfont1:{color:"#bcbcbc"}});

        }
        console.log(this.state.res);
    }
    render() {

        return (
            <div className="toast">
                   <div className="toasttop"></div>
                   <div className="toastcanle" onClick={this.props.fun}></div>
                   <div className="toastform">
                   <form action="">
                      诊断原因：<br></br>
                      <input type="text" placeholder="选填" value={this.state.reason} onChange={(e)=>{this.whenchange(0,e)}}/><br></br>
                      措施：<br></br>
                      <input type="text" placeholder="选填" value={this.state.measure} onChange={(e)=>{this.whenchange(1,e)}} /><br></br>
                      处理状态：<br></br>
                      <span style={this.state.bg1} onClick={this.nosolve}>&nbsp;&nbsp;&nbsp;&nbsp;</span> <span style={this.state.setfont1} onClick={this.nosolve}>已处理</span> &nbsp;&nbsp;&nbsp; <span style={this.state.bg2} onClick={this.solved}>&nbsp;&nbsp;&nbsp;&nbsp;</span> <span style={this.state.setfont2} onClick={this.solved}>未处理</span>
                   </form>
                   </div>
    
                   <div className="toastfoot">
                    <input type="button" value="取消" onClick={this.props.fun}></input>
                    <input type="button" value="提交" onClick={()=>{this.props.solved({diagnoseReason:this.state.reason,correctiveAction:this.state.measure},this.state.res);}}></input>
                   </div>
    
            </div>
        ) 
    }
}
export default Toast;